import { DateTime } from 'luxon'
import { createFormatter } from 'next-intl'

import type { KPIs } from '@/logged-in/components/Energy/state'
import type { LocalizedAndTimezoneDate } from '@/logged-in/utils/commonUtils'
import { formatCurrencyToParts, formatLocaleDate } from '@/logged-in/utils/commonUtils'
import type { TimeSeriesQueryOutputData } from '@/shared/graphql/queries/queryTypes'
import type {
  ConsumptionSeriesFragment,
  CostBasicFragment,
  CostType,
  CostUnit,
  DataResolution,
  MeasurementDates,
  MeasurementType,
  PriceAreaCode,
} from '@/shared/graphql/schema/commonBackend/graphql'
import type { useTheme } from '@/shared/hooks/useTheme'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

export const GRANULARITY_OPTIONS = ['Day', 'Month', 'Year'] as const

export type GranularityOption = (typeof GRANULARITY_OPTIONS)[number]

export type GraphType = 'Consumption' | 'Production'

type TranslationKeyFromGranularityOption = 'hour' | 'day' | 'month'

export const TooltipContainerId = 'consumption-tooltip-container'

export type ElementalColors = ReturnType<typeof useTheme>['colors']

export type KPI = {
  value: string
  average: string
  unit?: string
}

export type LegendSeriesId = 'cost' | 'temperature' | 'electricityPrice'

export type LegendSeries = {
  id: LegendSeriesId
  active?: boolean
  disabled?: boolean
}

export type TabOptionId = 'graph' | 'table'

export const DEFAULT_NUMBER_FORMAT = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

export const EmptyMap = {
  setter: {
    Day: (date: DateTime, value: number) => date.set({ hour: value }),
    Month: (date: DateTime, value: number) => date.set({ day: value }),
    Year: (date: DateTime, value: number) => date.set({ month: value }),
  },
  keyGetter: {
    Day: (date: DateTime) => date.hour,
    Month: (date: DateTime) => date.day - 1,
    Year: (date: DateTime) => date.month - 1,
  },
  count: {
    Day: () => 24,
    Month: (date: DateTime) => date.daysInMonth!,
    Year: () => 12,
  },
  indexOffset: {
    Day: 0,
    Month: 1,
    Year: 1,
  },
  isSame: {
    Day: (date1: DateTime, date2: DateTime) => date1.hasSame(date2, 'day'),
    Month: (date1: DateTime, date2: DateTime) => date1.hasSame(date2, 'month'),
    Year: (date1: DateTime, date2: DateTime) => date1.hasSame(date2, 'year'),
  },
} satisfies Record<string, Record<GranularityOption, unknown>>

export const VARIABLE_COST_ITEMS: Set<CostType> = new Set([
  'ELCERT_AMOUNT',
  'SPOT_VARIABLE_AMOUNT',
  'VAR_AMOUNT',
  'VAR_DISCOUNT_AMOUNT',
])

export const calculateVolumeBasedCost = (cost?: CostBasicFragment[] | null): number =>
  cost?.reduce((acc, cost) => (VARIABLE_COST_ITEMS.has(cost.type) ? acc + cost.total : acc), 0) ?? 0

type EnergyTotals = {
  totalEnergy: number
  averageEnergy: number
  totalCost: number
  averageCost: number
}

const calculateAverage = (total: number, count: number): number => (count > 0 ? total / count : 0)

export const calculateEnergyTotals = (
  consumptionSeries: ConsumptionSeriesFragment[] = [],
): EnergyTotals => {
  const { totalEnergy, totalCost } = consumptionSeries.reduce(
    (acc, entry) => ({
      totalEnergy:
        acc.totalEnergy + entry.energy.reduce((energyAcc, { value }) => energyAcc + value, 0),
      totalCost: acc.totalCost + calculateVolumeBasedCost(entry.cost),
    }),
    {
      totalEnergy: 0,
      totalCost: 0,
    },
  )
  const consumptionCount = consumptionSeries.filter((entry) => entry.energy.length > 0).length
  const costCount = consumptionSeries.filter((entry) => entry.cost != null).length
  return {
    totalEnergy,
    averageEnergy: calculateAverage(totalEnergy, consumptionCount),
    totalCost,
    averageCost: calculateAverage(totalCost, costCount),
  }
}

type ProcessDataParams = {
  activeDate: DateTime | null
  data: TimeSeriesQueryOutputData | undefined
  priceArea: PriceAreaCode
  latestMeasurementDate?: DateTime | null
}

export const processData = ({
  data: rawData,
  priceArea,
  latestMeasurementDate,
}: ProcessDataParams) => {
  if (!rawData) {
    return
  }

  const timeZone = getTimeZoneForPriceAreaCode(priceArea)
  const delivertSiteCategory = rawData.deliverySiteCategory
  const series = rawData.series.map((entry) => {
    //TEMPORARY FIX: Remove energy data which are after "latestMeasurementDate"
    if (
      latestMeasurementDate &&
      DateTime.fromISO(entry.atUTC).setZone(timeZone) > latestMeasurementDate.endOf('day')
    ) {
      entry.energy = []
      entry.cost = null
    }
    if (delivertSiteCategory === 'PRODUCTION') {
      entry.cost = entry.cost?.map((cost) => {
        //cost should be without VAT, to keep all calculations working, total is updated with value data
        return { ...cost, total: Math.abs(cost.value) }
      })
      entry.price = entry.price
        ? { total: Math.abs(entry.price.total), value: Math.abs(entry.price.value) }
        : null
    }
    return {
      ...entry,
      volumeBasedCost: calculateVolumeBasedCost(entry.cost),
    }
  })

  return {
    ...rawData,
    series,
  }
}

export const getDateResolution = (option: GranularityOption): DataResolution => {
  let newGranularity: DataResolution
  switch (option) {
    case 'Day':
      newGranularity = 'HOUR'
      break
    case 'Month':
      newGranularity = 'DAY'
      break
    case 'Year':
      newGranularity = 'MONTH'
      break
  }

  return newGranularity
}

export const getTranslationKeyFromGranularityOption = (
  option: GranularityOption,
): TranslationKeyFromGranularityOption => {
  let translationKey: TranslationKeyFromGranularityOption
  switch (option) {
    case 'Day':
      translationKey = 'hour'
      break
    case 'Month':
      translationKey = 'day'
      break
    case 'Year':
      translationKey = 'month'
      break
  }

  return translationKey
}

export const resolutionTimeFormatter = (
  granularity: GranularityOption,
  date: LocalizedAndTimezoneDate,
  showFullMonth: boolean = false,
) => {
  switch (granularity) {
    case 'Year':
      const defaultMonth = formatLocaleDate(date, `MMMM`)
      const capitalizedMonth = defaultMonth.slice(0, 1).toUpperCase()
      return showFullMonth ? capitalizedMonth + defaultMonth.slice(1) : capitalizedMonth
    case 'Month':
      return formatLocaleDate(date, `d`)
    case 'Day':
      return formatLocaleDate(date, `HH.mm`)
  }
}

type CalculateKPIsParams = {
  consumptionSeries: ConsumptionSeriesFragment[]
  measurementUnit: string
  currency: CostUnit
  locale: string
}

export const calculateKPIs = ({
  consumptionSeries,
  measurementUnit,
  currency,
  locale,
}: CalculateKPIsParams): KPIs => {
  const { number } = createFormatter({ locale })
  const { totalEnergy, averageEnergy, totalCost, averageCost } =
    calculateEnergyTotals(consumptionSeries)

  return {
    total: {
      value: number(totalEnergy, DEFAULT_NUMBER_FORMAT),
      average: number(averageEnergy, DEFAULT_NUMBER_FORMAT),
      unit: measurementUnit,
    },
    cost: {
      value: number(totalCost, DEFAULT_NUMBER_FORMAT),
      average: number(averageCost, DEFAULT_NUMBER_FORMAT),
      unit: getCostUnit(currency, locale),
    },
  }
}

export const getGranularitiesByMeasurementType = (
  measurementType: MeasurementType,
): GranularityOption[] => {
  if (measurementType === 'HOURLY' || measurementType === 'PER_15_MIN') {
    return ['Day', 'Month', 'Year'] // return all options for hourly / per15min type
  }
  return ['Year']
}

const getEarliestDate = (dates: Array<{ firstDate?: string | null }>): string | null =>
  dates
    .filter((data) => data.firstDate !== null && data.firstDate !== undefined)
    .map((data) => data.firstDate)
    .toSorted((a, b) => (a && b ? new Date(a).getTime() - new Date(b).getTime() : 0))[0] ?? null
const getLatestDate = (dates: MeasurementDates[]): string | null =>
  dates
    .map((data) => data.latestDate)
    .filter((date) => date !== null && date !== undefined)
    .toSorted((a, b) => (a && b ? new Date(b).getTime() - new Date(a).getTime() : 0))[0] ?? null

const getFirstMeasurementDate = (
  measurementType: MeasurementType,
  granularity: GranularityOption,
  measurementDates: MeasurementDates[],
  hourlyDates: MeasurementDates[],
  per15minDates: MeasurementDates[],
) => {
  // For MeasurementType "monthly" and for other MeasurementTypes for yearly granularity, first date is earliest first date among all types

  if (measurementType === 'MONTHLY' || granularity === 'Year') {
    return getEarliestDate(measurementDates) ?? null
  }
  // For other granularities, first date is first date of type 'hourly' or 'per15min'
  const earliestHourlyDate = getEarliestDate(hourlyDates.concat(per15minDates)) ?? null
  return earliestHourlyDate
}

const getLatestMeasurementDate = (
  measurementType: MeasurementType,
  monthlyDates: MeasurementDates[],
  hourlyDates: MeasurementDates[],
  per15minDates: MeasurementDates[],
) => {
  // For MeasurementType "monthly", latest date is the latest date of type 'monthly'
  if (measurementType === 'MONTHLY') {
    return getLatestDate(monthlyDates)
  }
  // For other MeasurementTypes, for all granularities, latest date is latest date of type 'hourly' or 'per15min'
  const latestHourlyDate = getLatestDate(hourlyDates.concat(per15minDates)) ?? null
  return latestHourlyDate
}

export const getMeasurementDates = (
  measurementType: MeasurementType,
  measurementDates: MeasurementDates[],
  granularity: GranularityOption,
  priceArea: PriceAreaCode,
): { firstMeasurementDate: DateTime | null; latestMeasurementDate: DateTime | null } => {
  const filterMeasurementDatesByType = (
    measurementDates: MeasurementDates[],
    type: MeasurementType,
  ): MeasurementDates[] => measurementDates.filter((date) => date.type === type)

  const monthlyDates = filterMeasurementDatesByType(measurementDates, 'MONTHLY')
  const hourlyDates = filterMeasurementDatesByType(measurementDates, 'HOURLY')
  const per15minDates = filterMeasurementDatesByType(measurementDates, 'PER_15_MIN')

  const firstMeasurementDate = getFirstMeasurementDate(
    measurementType,
    granularity,
    measurementDates,
    hourlyDates,
    per15minDates,
  )
  const latestMeasurementDate = getLatestMeasurementDate(
    measurementType,
    monthlyDates,
    hourlyDates,
    per15minDates,
  )

  const timeZone = getTimeZoneForPriceAreaCode(priceArea)

  return {
    firstMeasurementDate: firstMeasurementDate
      ? DateTime.fromISO(firstMeasurementDate).setZone(timeZone)
      : null,
    latestMeasurementDate: latestMeasurementDate
      ? DateTime.fromISO(latestMeasurementDate).setZone(timeZone)
      : null,
  }
}

export type SerializedDateTimeWithSetZone = {
  iso: string
  zone: string
}

export const serializeDateTimeWithSetZone = (date: DateTime): SerializedDateTimeWithSetZone => ({
  iso: date.toISO()!,
  zone: date.zoneName!,
})

export const parseSerializedDateTimeWithSetZone = (date: SerializedDateTimeWithSetZone) =>
  DateTime.fromISO(date.iso).setZone(date.zone)

export const getCostUnit = (costUnit: CostUnit | undefined, locale: string) => {
  if (!costUnit) {
    return ''
  }
  const { currency } = formatCurrencyToParts(0, costUnit, locale)
  return currency
}

export const dateBrowserAriaLabels = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: any,
  activeDate: DateTime<boolean> | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateTime: any,
) => ({
  prevButtonAriaLabel: t('previousLabel'),
  nextButtonAriaLabel: t('nextLabel'),
  label: `${t('selectDateLabel')}: ${activeDate && dateTime(activeDate.toJSDate(), { dateStyle: 'long' })}`,
})
